import React, { useState, useEffect } from 'react';
import { FaFileImage, FaFilePdf } from 'react-icons/fa';
import axiosInstance from '../../../../API/axiosInstance';

const AssuranceAjout = () => {
  // Champs de base
  const [nom, setNom] = useState('');
  const [description, setDescription] = useState('');
  const [prix, setPrix] = useState('');
  const [engagement, setEngagement] = React.useState(12);
  const [prixVeloInferieur, setPrixVeloInferieur] = useState('');
  const [status, setStatus] = useState('');
  const [statuses, setStatuses] = useState([]);
  const [assureur, setAssureur] = useState('');
  const [assureurs, setAssureurs] = useState([]);
  const [categorie, setCategorie] = useState('');
  const [categories, setCategories] = useState([]);
  const [secteur, setSecteur] = useState('');
  const [secteurs, setSecteurs] = useState([]);
  const [image, setImage] = useState(null);
  const [pdfIpid, setPdfIpid] = useState(null);
  const [pdfNotice, setPdfNotice] = useState(null);
  const [message, setMessage] = useState(''); // Message temporaire

  // Avantages
  const [avantages, setAvantages] = useState([{ titre: '', description: '' }]);

  // Garanties et franchises
  const [vol, setVol] = useState(false);
  const [tentativeVol, setTentativeVol] = useState(false);
  const [dommagesMateriels, setDommagesMateriels] = useState(false);
  const [catastrophesNaturelles, setCatastrophesNaturelles] = useState(false);
  const [catastrophesTechnologiques, setCatastrophesTechnologiques] = useState(false);
  const [franchisePercent, setFranchisePercent] = useState({
    vol: '',
    tentativeVol: '',
    dommagesMateriels: '',
    catastrophesNaturelles: '',
    catastrophesTechnologiques: ''
  });
  const [minFranchise, setMinFranchise] = useState({
    vol: '',
    tentativeVol: '',
    dommagesMateriels: '',
    catastrophesNaturelles: '',
    catastrophesTechnologiques: ''
  });

  // Récupération des données pour les listes déroulantes
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [statusRes, assureursRes, secteursRes] = await Promise.all([
          axiosInstance.get('/status/get-statuses'),
          axiosInstance.get('/assureurs/get-assureurs'),
          axiosInstance.get('/secteurs/get-secteurs')
        ]);
        setStatuses(statusRes.data.statuses);
        setAssureurs(assureursRes.data.assureurs);
        setSecteurs(secteursRes.data.secteurs);
      } catch (error) {
        console.error('Erreur lors de la récupération des données :', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      if (!secteur) return;
      try {
        const response = await axiosInstance.get(`/categories/get-categories/${secteur}`);
        setCategories(response.data.categories);
      } catch (error) {
        console.error('Erreur lors de la récupération des catégories :', error);
      }
    };
    fetchCategories();
  }, [secteur]);

  // Gestion du message temporaire
  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => setMessage(''), 3000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  // Gestion des avantages
  const ajouterAvantage = () => {
    setAvantages([...avantages, { titre: '', description: '' }]);
  };

  const handleAvantageChange = (index, field, value) => {
    const newAvantages = [...avantages];
    newAvantages[index][field] = value;
    setAvantages(newAvantages);
  };

  const supprimerAvantage = (index) => {
    const newAvantages = avantages.filter((_, i) => i !== index);
    setAvantages(newAvantages);
  };

  // Soumission du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    // Champs textuels
    formData.append('nom', nom);
    formData.append('description', description);
    formData.append('prix', prix);
    formData.append('engagement', engagement)
    formData.append('prixVeloInferieur', prixVeloInferieur);
    formData.append('status_id', status);
    formData.append('assureur_id', assureur);
    formData.append('category_id', categorie);
    formData.append('secteur_id', secteur);

    // Garanties booléennes
    formData.append('vol', vol ? 1 : 0);
    formData.append('tentative_vol', tentativeVol ? 1 : 0);
    formData.append('dommages_materiels', dommagesMateriels ? 1 : 0);
    formData.append('catastrophes_naturelles', catastrophesNaturelles ? 1 : 0);
    formData.append('catastrophes_technologiques', catastrophesTechnologiques ? 1 : 0);

    // Champs de franchise
    Object.keys(franchisePercent).forEach((key) => {
      formData.append(`${key}_franchise`, franchisePercent[key]);
    });
    Object.keys(minFranchise).forEach((key) => {
      formData.append(`min_fr_${key}`, minFranchise[key]);
    });

    // Avantages
    avantages.forEach((avantage, index) => {
      formData.append(`avantages[${index}][titre]`, avantage.titre);
      formData.append(`avantages[${index}][description]`, avantage.description);
    });

    // Pièces jointes
    if (image) formData.append('image', image);
    if (pdfIpid) formData.append('pdf_ipid', pdfIpid);
    if (pdfNotice) formData.append('pdf_notice', pdfNotice);

    try {
      await axiosInstance.post('/assurances/add-assurance', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setMessage('Assurance ajoutée avec succès !');

      // Réinitialiser les champs
      setNom('');
      setDescription('');
      setPrix('');
      setEngagement('');
      setPrixVeloInferieur('');
      setStatus('');
      setAssureur('');
      setCategorie('');
      setSecteur('');
      setImage(null);
      setPdfIpid(null);
      setPdfNotice(null);
      setVol(false);
      setTentativeVol(false);
      setDommagesMateriels(false);
      setCatastrophesNaturelles(false);
      setCatastrophesTechnologiques(false);
      setFranchisePercent({
        vol: '',
        tentativeVol: '',
        dommagesMateriels: '',
        catastrophesNaturelles: '',
        catastrophesTechnologiques: ''
      });
      setMinFranchise({
        vol: '',
        tentativeVol: '',
        dommagesMateriels: '',
        catastrophesNaturelles: '',
        catastrophesTechnologiques: ''
      });
      setAvantages([{ titre: '', description: '' }]);
    } catch (error) {
      console.error('Erreur lors de l\'ajout de l\'assurance :', error);
      setMessage('Erreur lors de l\'ajout de l\'assurance.');
    }
  };

  
  return (
    <div className="container-fluid">
      <div className="card shadow mb-4 col-md-8">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">Ajouter une Assurance</h6>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <h5>Informations de Base</h5>
            <div className="form-group mb-3">
              <label>Nom de l'Assurance</label>
              <input type="text" className="form-control" value={nom} onChange={(e) => setNom(e.target.value)} required />
            </div>
            <div className="form-group mb-3">
              <label>Description</label>
              <textarea className="form-control" value={description} onChange={(e) => setDescription(e.target.value)} required />
            </div>
            <div className="form-row mb-3">
              <div className="col">
                <label>Prix</label>
                <input type="number" className="form-control" value={prix} onChange={(e) => setPrix(e.target.value)} required />
              </div>
              <div className="col">
                <label>Prix Vélo Minimum</label>
                <input type="number" className="form-control" value={prixVeloInferieur} onChange={(e) => setPrixVeloInferieur(e.target.value)} />
              </div>
            </div>

            <div className="form-group mb-3">
              <label>Status</label>
              <select className="form-control" value={status} onChange={(e) => setStatus(e.target.value)} required>
                <option value="">Sélectionnez un statut</option>
                {statuses.map((statut) => (
                  <option key={statut.id || statut.name} value={statut.id}>
                    {statut.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group mb-3">
              <label>Assureur</label>
              <select className="form-control" value={assureur} onChange={(e) => setAssureur(e.target.value)} required>
                <option value="">Sélectionnez un assureur</option>
                {assureurs.map((assureur) => (
                  <option key={assureur.id || assureur.nom} value={assureur.id}>
                    {assureur.nom}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group mb-3">
              <label>Secteur</label>
              <select className="form-control" value={secteur} onChange={(e) => setSecteur(e.target.value)} required>
                <option value="">Sélectionnez un secteur</option>
                {secteurs.map((secteur) => (
                  <option key={secteur.id || secteur.nom} value={secteur.id}>
                    {secteur.nom}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group mb-3">
              <label>Catégorie</label>
              <select className="form-control" value={categorie} onChange={(e) => setCategorie(e.target.value)} required>
                <option value="">Sélectionnez une catégorie</option>
                {categories.map((categorie) => (
                  <option key={categorie.id || categorie.nom} value={categorie.id}>
                    {categorie.nom}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-row mb-3">
              <div className="col">
                <label>Engagement (mois)</label>
                <input
                  type="number"
                  className="form-control"
                  value={engagement}
                  onChange={(e) => setEngagement(e.target.value)}
                  required
                />
              </div>
            </div>


            {/* Garanties */}
            <h5>Garanties</h5>
            {/* Garantie Vol */}
            <div className="form-check mb-2">
              <input type="checkbox" className="form-check-input" id="vol" checked={vol} onChange={(e) => setVol(e.target.checked)} />
              <label className="form-check-label" htmlFor="vol">Vol</label>
            </div>
            {vol && (
              <div className="form-row">
                <div className="col">
                  <label>Pourcentage de la Franchise (Vol)</label>
                  <input type="number" className="form-control" value={franchisePercent.vol} onChange={(e) => setFranchisePercent((prev) => ({ ...prev, vol: e.target.value }))} />
                </div>
                <div className="col">
                  <label>Franchise Minimum (Vol)</label>
                  <input type="number" className="form-control" value={minFranchise.vol} onChange={(e) => setMinFranchise((prev) => ({ ...prev, vol: e.target.value }))} />
                </div>
              </div>
            )}

            {/* Garantie Tentative de Vol */}
            <div className="form-check mb-2">
              <input type="checkbox" className="form-check-input" id="tentativeVol" checked={tentativeVol} onChange={(e) => setTentativeVol(e.target.checked)} />
              <label className="form-check-label" htmlFor="tentativeVol">Tentative de vol</label>
            </div>
            {tentativeVol && (
              <div className="form-row">
                <div className="col">
                  <label>Pourcentage de la Franchise (Tentative de Vol)</label>
                  <input type="number" className="form-control" value={franchisePercent.tentativeVol} onChange={(e) => setFranchisePercent((prev) => ({ ...prev, tentativeVol: e.target.value }))} />
                </div>
                <div className="col">
                  <label>Franchise Minimum (Tentative de Vol)</label>
                  <input type="number" className="form-control" value={minFranchise.tentativeVol} onChange={(e) => setMinFranchise((prev) => ({ ...prev, tentativeVol: e.target.value }))} />
                </div>
              </div>
            )}

            {/* Garantie Dommages Matériels */}
            <div className="form-check mb-2">
              <input type="checkbox" className="form-check-input" id="dommagesMateriels" checked={dommagesMateriels} onChange={(e) => setDommagesMateriels(e.target.checked)} />
              <label className="form-check-label" htmlFor="dommagesMateriels">Dommages matériels</label>
            </div>
            {dommagesMateriels && (
              <div className="form-row">
                <div className="col">
                  <label>Pourcentage de la Franchise (Dommages Matériels)</label>
                  <input type="number" className="form-control" value={franchisePercent.dommagesMateriels} onChange={(e) => setFranchisePercent((prev) => ({ ...prev, dommagesMateriels: e.target.value }))} />
                </div>
                <div className="col">
                  <label>Franchise Minimum (Dommages Matériels)</label>
                  <input type="number" className="form-control" value={minFranchise.dommagesMateriels} onChange={(e) => setMinFranchise((prev) => ({ ...prev, dommagesMateriels: e.target.value }))} />
                </div>
              </div>
            )}

            {/* Garantie Catastrophes Naturelles */}
            <div className="form-check mb-2">
              <input type="checkbox" className="form-check-input" id="catastrophesNaturelles" checked={catastrophesNaturelles} onChange={(e) => setCatastrophesNaturelles(e.target.checked)} />
              <label className="form-check-label" htmlFor="catastrophesNaturelles">Catastrophes naturelles</label>
            </div>
            {catastrophesNaturelles && (
              <div className="form-row">
                <div className="col">
                  <label>Pourcentage de la Franch  ise (Catastrophes Naturelles)</label>
                  <input type="number" className="form-control" value={franchisePercent.catastrophesNaturelles} onChange={(e) => setFranchisePercent((prev) => ({ ...prev, catastrophesNaturelles: e.target.value }))} />
                </div>
                <div className="col">
                  <label>Franchise Minimum (Catastrophes Naturelles)</label>
                  <input type="number" className="form-control" value={minFranchise.catastrophesNaturelles} onChange={(e) => setMinFranchise((prev) => ({ ...prev, catastrophesNaturelles: e.target.value }))} />
                </div>
              </div>
            )}

            {/* Garantie Catastrophes Technologiques */}
            <div className="form-check mb-2">
              <input type="checkbox" className="form-check-input" id="catastrophesTechnologiques" checked={catastrophesTechnologiques} onChange={(e) => setCatastrophesTechnologiques(e.target.checked)} />
              <label className="form-check-label" htmlFor="catastrophesTechnologiques">Catastrophes technologiques</label>
            </div>
            {catastrophesTechnologiques && (
              <div className="form-row">
                <div className="col">
                  <label>Pourcentage de la Franchise (Catastrophes Technologiques)</label>
                  <input type="number" className="form-control" value={franchisePercent.catastrophesTechnologiques} onChange={(e) => setFranchisePercent((prev) => ({ ...prev, catastrophesTechnologiques: e.target.value }))} />
                </div>
                <div className="col">
                  <label>Franchise Minimum (Catastrophes Technologiques)</label>
                  <input type="number" className="form-control" value={minFranchise.catastrophesTechnologiques} onChange={(e) => setMinFranchise((prev) => ({ ...prev, catastrophesTechnologiques: e.target.value }))} />
                </div>
              </div>
            )}

            <h5>Avantages</h5>
            {avantages.map((avantage, index) => (
              <div key={index} className="form-group">
                <label>Titre</label>
                <input
                  type="text"
                  className="form-control"
                  value={avantage.titre}
                  onChange={(e) => handleAvantageChange(index, 'titre', e.target.value)}
                  required
                />
                <label>Description</label>
                <textarea
                  className="form-control"
                  value={avantage.description}
                  onChange={(e) => handleAvantageChange(index, 'description', e.target.value)}
                  required
                />
                <button
                  type="button"
                  className="btn btn-danger mt-2"
                  onClick={() => supprimerAvantage(index)}
                >
                  Supprimer
                </button>
              </div>
            ))}
            <button type="button" className="btn btn-primary mt-2" onClick={ajouterAvantage}>
              Ajouter un avantage
            </button>

           {/* Pièces Jointes */}
           <h5 className="mt-4">Pièces Jointes</h5>
            
            <div className="form-group mb-3">
              <label className="btn btn-outline-primary btn-block">
                <FaFileImage className="mr-2" /> Importer une Image
                <input
                  type="file"
                  className="d-none"
                  onChange={(e) => setImage(e.target.files[0])}
                />
              </label>
              {image && <small className="form-text text-muted">{image.name}</small>}
            </div>

            <div className="form-group mb-3">
              <label className="btn btn-outline-primary btn-block">
                <FaFilePdf className="mr-2" /> Importer PDF IPID
                <input
                  type="file"
                  className="d-none"
                  onChange={(e) => setPdfIpid(e.target.files[0])}
                  required
                />
              </label>
              {pdfIpid && <small className="form-text text-muted">{pdfIpid.name}</small>}
            </div>

            <div className="form-group mb-3">
              <label className="btn btn-outline-primary btn-block">
                <FaFilePdf className="mr-2" /> Importer PDF Notice d'Informations
                <input
                  type="file"
                  className="d-none"
                  onChange={(e) => setPdfNotice(e.target.files[0])}
                  required
                />
              </label>
              {pdfNotice && <small className="form-text text-muted">{pdfNotice.name}</small>}
            </div>
            {/* Bouton de soumission */}
            <button type="submit" className="btn btn-primary mt-3">Ajouter Assurance</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AssuranceAjout;
