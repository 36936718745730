import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './LoadingPayment.module.css';
import { useTranslation } from 'react-i18next';
import chargementImage from '../../../assets/images/vendor/chargement.png';
import axiosInstance from '../../../API/axiosInstance';

const LoadingPayment = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { transactionId, clientId, formData } = location.state || {};
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);
  const [timeLeft, setTimeLeft] = useState(300); // 5 minutes en secondes
  const [buttonVisible, setButtonVisible] = useState(false);
  const [timerFinished, setTimerFinished] = useState(false);

  const [isResendEmailDisabled, setIsResendEmailDisabled] = useState(false);
  const [timeLeftResendEmail, setTimeLeftResendEmail] = useState(0);
  const [showEmailSentPopup, setShowEmailSentPopup] = useState(false);

  // Timer logique
  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft((prev) => prev - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setTimerFinished(true);
    }
  }, [timeLeft]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`; // Ajoute un "0" si les secondes sont < 10
  };

  // Redirection automatique en cas de timer terminé
  useEffect(() => {
    if (timerFinished) {
      setStatus('failed');
      setLoading(false);
    }
  }, [timerFinished]);

  // Afficher les boutons après 20 secondes
  useEffect(() => {
    if (timeLeft <= 240 && !buttonVisible) {
      setButtonVisible(true);
    }
  }, [timeLeft]);

  // Vérification du statut de la transaction
  useEffect(() => {
    const checkTransactionStatus = async () => {
      if (!transactionId || timerFinished) return;

      try {
        const response = await axiosInstance.get(`/transaction-status/${transactionId}`, {
          headers: { 'Cache-Control': 'no-cache' },
        });

        const currentStatus = response.data.status;

        if (currentStatus === 'success' || currentStatus === 'failed') {
          setStatus(currentStatus);
          setLoading(false);
        }
      } catch (err) {
        if (err.response?.status === 404) {
          setError(t('Transaction non trouvée. Veuillez vérifier le numéro de transaction.'));
        } else {
          setError(t('Erreur lors de la récupération du statut de la transaction.'));
        }
        setLoading(false);
      }
    };

    const interval = setInterval(checkTransactionStatus, 5000);
    return () => clearInterval(interval);
  }, [transactionId, timerFinished, t]);

  // Relancer une transaction existante
  const handleRetryTransaction = async () => {
    try {
      if (!transactionId) {
        setError(t('Transaction introuvable pour la relance.'));
        return;
      }

      const response = await axiosInstance.post('/restart-transaction', { transactionId });
      const { transactionId: newTransactionId, paymentUrl } = response.data;

      // Redirection vers la même page pour relancer le processus
      navigate('/vendor-panel/loading-payment', {
        state: { transactionId: newTransactionId, clientId, formData, paymentUrl },
      });

      // Réinitialiser les états pour recommencer le processus
      setStatus(null);
      setError(null);
      setTimeLeft(30);
      setTimerFinished(false);
      setButtonVisible(false);
      setLoading(true);
    } catch (err) {
      setError(err.response?.data?.message || t('Erreur inconnue lors de la relance.'));
    }
  };

  // Relancer une nouvelle transaction
  const handleNewTransaction = async () => {
    try {
      if (!clientId || !formData) {
        setError(t('Impossible de relancer la transaction : données manquantes.'));
        return;
      }

      const response = await axiosInstance.post('/start-transaction', { clientId });
      const { transactionId: newTransactionId, paymentUrl } = response.data;

      // Redirection vers la page de chargement avec une nouvelle transaction
      navigate('/vendor-panel/loading-payment', {
        state: { transactionId: newTransactionId, clientId, formData, paymentUrl },
      });

      // Réinitialiser les états pour recommencer le processus
      setStatus(null);
      setError(null);
      setTimeLeft(30);
      setTimerFinished(false);
      setButtonVisible(false);
      setLoading(true);
    } catch (err) {
      setError(err.response?.data?.message || t('Erreur inconnue lors de la création.'));
    }
  };

  // Réenvoyer un email
  const handleResendEmail = async () => {
    try {
      if (!transactionId) {
        setError(t('Transaction introuvable pour renvoyer l’email.'));
        return;
      }

      setIsResendEmailDisabled(true);
      setTimeLeftResendEmail(60);

      await axiosInstance.post('/resend-transaction-email', { transactionId });
      setShowEmailSentPopup(true);

      const interval = setInterval(() => {
        setTimeLeftResendEmail((prev) => {
          if (prev <= 1) {
            clearInterval(interval);
            setIsResendEmailDisabled(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } catch (err) {
      setError(t('Erreur lors de l\'envoi de l\'email.'));
    }
  };

  // Ajouter du temps au timer
  const handleAddTime = () => {
    setTimeLeft((prev) => prev + 60);
  };

  // Annuler et retourner au récapitulatif
  const handleCancel = () => {
    navigate('/vendor-panel/parcours-client');
  };

  const closeEmailSentPopup = () => {
    setShowEmailSentPopup(false);
  };

  // Afficher une page d'échec après expiration du timer
  if (!loading && status === 'failed') {
    return (
    <div className="container vh-100 d-flex align-items-center justify-content-center">
      <div className="row w-100 align-items-center">
        {/* Texte et boutons à gauche */}
        <div className="col-md-6 text-md-start">
          <h2 className="text-danger">{t('Transaction échouée')}</h2>
          <p className="mt-3">
            {t('Le délai pour la transaction a expiré ou elle a échoué. Vous pouvez relancer une nouvelle transaction.')}
          </p>

          {/* Boutons avec espacement explicite */}
          <div className="mt-4">
            <button
              className="btn"
              style={{
                backgroundColor: '#4eb933',
                color: 'white',
                marginRight: '15px', // Espacement à droite
              }}
              onClick={handleRetryTransaction}
            >
              {t('Relancer une transaction')}
            </button>
            <button
              className="btn btn-secondary"
              style={{
                marginLeft: '15px', // Espacement à gauche pour un rendu équilibré
              }}
              onClick={handleCancel}
            >
              {t('Retour au tableau de bord')}
            </button>
          </div>
        </div>

        {/* Image à droite */}
        <div className="col-md-6 text-center">
          <img
            src={chargementImage}
            alt={t('Transaction échouée')}
            className="img-fluid rounded"
          />
        </div>
      </div>
    </div>
    );
  }

  // Afficher la page de chargement
  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.loadingSpinner}></div>
        <p>{t('Chargement en cours...')}</p>
        <p><strong>{t('Temps restant :')} {formatTime(timeLeft)}</strong></p>
        {buttonVisible && (
          <div className={styles.buttonContainer}>
            <button className={styles.completeTransactionButton} onClick={handleRetryTransaction}>
              {t('Relancer la transaction')}
            </button>
            <button className={styles.completeTransactionButton} onClick={handleAddTime}>
              {t('Ajouter 1 minute')}
            </button>
            <button
              className={`${styles.completeTransactionButton} ${isResendEmailDisabled ? styles.disabledButton : ''}`}
              onClick={handleResendEmail}
              disabled={isResendEmailDisabled}
            >
              {isResendEmailDisabled
                ? `${t('Renvoyer l\'email')} (${timeLeftResendEmail}s)`
                : t('Renvoyer l\'email')}
            </button>
          </div>
        )}
        <div className={styles.buttonContainer}>
          <button className={styles.cancelButton} onClick={handleCancel}>
            {t('Annuler')}
          </button>
        </div>
        {showEmailSentPopup && (
          <div className={styles.popup}>
            <div className={styles.popupContent}>
              <h3>{t('Email envoyé')}</h3>
              <p>{t('L\'email a été renvoyé avec succès.')}</p>
              <button onClick={closeEmailSentPopup} className={styles.popupButton}>
                {t('Fermer')}
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }

  // Afficher la page de succès
  return (
    <div className="container vh-100 d-flex align-items-center justify-content-center">
      <div className="row w-100 align-items-center">
        {/* Texte et boutons à gauche */}
        <div className="col-md-6 text-md-start">
          <h2 className="text-success">{t('Transaction réussie')}</h2>
          <p className="mt-3">
            {t('Votre transaction a été traitée avec succès. Vous pouvez retourner au tableau de bord ou lancer une nouvelle action.')}
          </p>
  
          {/* Boutons avec espacement explicite */}
          <div className="mt-4">
            <button
              className="btn"
              style={{
                backgroundColor: '#4eb933',
                color: 'white',
                marginRight: '15px', // Espacement à droite
              }}
              onClick={handleCancel}
            >
              {t('Retour au tableau de bord')}
            </button>
            <button
              className="btn btn-secondary"
              style={{
                marginLeft: '15px', // Espacement à gauche pour un rendu équilibré
              }}
              onClick={handleRetryTransaction}
            >
              {t('Effectuer une nouvelle transaction')}
            </button>
          </div>
        </div>
  
        {/* Image à droite */}
        <div className="col-md-6 text-center">
          <img
            src={chargementImage}
            alt={t('Transaction réussie')}
            className="img-fluid rounded"
          />
        </div>
      </div>
    </div>
  );  
};

export default LoadingPayment;
