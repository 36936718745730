import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import axiosInstance from '../../../../API/axiosInstance'; 

const AssuranceForm = ({ selectedAssurance, setSelectedAssurance, setMessage, updateAssuranceList }) => {
  const [nom, setNom] = useState('');
  const [description, setDescription] = useState('');
  const [categorie, setCategorie] = useState('');
  const [prix, setPrix] = useState('');
  const [code_product, setCodeproduct] = useState('');
  const [engagement, setEngagement] = useState('');
  const [prixVeloInferieur, setPrixVeloInferieur] = useState('');
  const [status, setStatus] = useState('');
  const [secteur, setSecteur] = useState('');
  const [assureur, setAssureur] = useState('');
  const [image, setImage] = useState(null);
  const [pdfIpid, setPdfIpid] = useState(null);
  const [pdfNotice, setPdfNotice] = useState(null);
  const [categories, setCategories] = useState([]);
  const [assureurs, setAssureurs] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [secteurs, setSecteurs] = useState([]);
  const [isLocked, setIsLocked] = useState(true);
  const [avantages, setAvantages] = useState([]);

  const [guarantees, setGuarantees] = useState([
    { name: 'vol', label: 'Vol', checked: false, franchisePercent: '', minFranchise: '' },
    { name: 'tentative_vol', label: 'Tentative de Vol', checked: false, franchisePercent: '', minFranchise: '' },
    { name: 'dommages_materiels', label: 'Dommages Matériels', checked: false, franchisePercent: '', minFranchise: '' },
    { name: 'catastrophes_naturelles', label: 'Catastrophes Naturelles', checked: false, franchisePercent: '', minFranchise: '' },
    { name: 'catastrophes_technologiques', label: 'Catastrophes Technologiques', checked: false, franchisePercent: '', minFranchise: '' }
  ]);

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const [categoriesRes, assureursRes, statusesRes, secteursRes] = await Promise.all([
          axiosInstance.get('/categories/get-categories'),
          axiosInstance.get('/assureurs/get-assureurs'),
          axiosInstance.get('/status/get-statuses'),
          axiosInstance.get('/secteurs/get-secteurs')
        ]);
        setCategories(categoriesRes.data.categories);
        setAssureurs(assureursRes.data.assureurs);
        setStatuses(statusesRes.data.statuses);
        setSecteurs(secteursRes.data.secteurs);
      } catch (error) {
        console.error("Erreur lors de la récupération des filtres:", error);
      }
    };
    fetchFilters();
  }, []);

  useEffect(() => {
    if (selectedAssurance && selectedAssurance.id) {
      axiosInstance.get(`/assurances/${selectedAssurance.id}`)
        .then((response) => {
          const assurance = response.data;
          setNom(assurance.nom || '');
          setDescription(assurance.description || '');
          setCategorie(assurance.category_id || '');
          setPrix(assurance.prix || '');
          setEngagement(assurance.engagement || '');
          setCodeproduct(assurance.code_product || '');
          setPrixVeloInferieur(assurance.prix_velo_inferieur || '');
          setStatus(assurance.status_id || '');
          setSecteur(assurance.secteur_id || '');
          setAssureur(assurance.assureur_id || '');
          setAvantages(assurance.avantages || []); // Charger les avantages
          setGuarantees((prevGuarantees) =>
            prevGuarantees.map((g) => ({
              ...g,
              checked: assurance[g.name] || false,
              franchisePercent: assurance[`${g.name}_franchise`] || '',
              minFranchise: assurance[`min_fr_${g.name}`] || ''
            }))
          );
          if (assurance.image) setImage(`data:image/jpeg;base64,${assurance.image}`);
          if (assurance.pdf_ipid) setPdfIpid(assurance.pdf_ipid);
          if (assurance.pdf_notice) setPdfNotice(assurance.pdf_notice);
        })
        .catch((error) => {
          console.error("Erreur lors de la récupération des détails de l'assurance :", error);
        });
    }
  }, [selectedAssurance]);
  
  
  const toggleDescription = (index) => {
    setAvantages((prevAvantages) =>
      prevAvantages.map((avantage, i) =>
        i === index ? { ...avantage, expanded: !avantage.expanded } : avantage
      )
    );
  };

  const handlePdfClick = (base64Data) => {
    if (!base64Data) {
      alert("Le document PDF est manquant.");
      return;
    }

    const binaryString = window.atob(base64Data);
    const bytes = Uint8Array.from(binaryString, (char) => char.charCodeAt(0));
    const blob = new Blob([bytes], { type: 'application/pdf' });
    return URL.createObjectURL(blob);
  };

  const handleGuaranteeChange = (index, field, value) => {
    setGuarantees(prev =>
      prev.map((g, i) => (i === index ? { ...g, [field]: value } : g))
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
  
    formData.append('nom', nom);
    formData.append('description', description);
    formData.append('prix', prix);
    formData.append('engagement', engagement);
    formData.append('prix_velo_inferieur', prixVeloInferieur);
    formData.append('status_id', status);
    formData.append('assureur_id', assureur);
    formData.append('category_id', categorie);
    formData.append('secteur_id', secteur);
  
    guarantees.forEach(guarantee => {
      formData.append(guarantee.name, guarantee.checked ? 1 : 0);
      formData.append(`${guarantee.name}_franchise`, guarantee.franchisePercent);
      formData.append(`min_fr_${guarantee.name}`, guarantee.minFranchise);
    });
  
    avantages.forEach((avantage, index) => {
      formData.append(`avantages[${index}][titre]`, avantage.titre);
      formData.append(`avantages[${index}][description]`, avantage.description);
    });
  
    if (image) formData.append('image', image);
    if (pdfIpid) formData.append('pdf_ipid', pdfIpid);
    if (pdfNotice) formData.append('pdf_notice', pdfNotice);
  
    try {
      let response;
      if (selectedAssurance && selectedAssurance.id) {
        response = await axiosInstance.put(`/assurances/update-assurance/${selectedAssurance.id}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
      } else {
        response = await axiosInstance.post(`/assurances`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
      }
  
      if (response.status === 200) {
        setMessage(`Assurance ${selectedAssurance ? 'mise à jour' : 'ajoutée'} avec succès!`);
        updateAssuranceList();
        if (!selectedAssurance) resetForm();
        setIsLocked(true);
      }
    } catch (error) {
      console.error("Erreur lors de la soumission du formulaire:", error);
      setMessage(`Erreur lors de ${selectedAssurance ? 'la mise à jour' : "l'ajout"} de l'assurance!`);
    }
  };
  

  const handleDeleteAssurance = async () => {
    if (selectedAssurance && selectedAssurance.id) {
      try {
        const response = await axiosInstance.put(`/assurances/${selectedAssurance.id}`);
        // const response = await axios.delete(`http://localhost:5000/api/assurances/${selectedAssurance.id}`);
        if (response.status === 200) {
          setMessage('Assurance supprimée avec succès!');
          updateAssuranceList();
          setSelectedAssurance(null);
          resetForm();
        }
      } catch (error) {
        console.error("Erreur lors de la suppression de l'assurance:", error);
        setMessage("Erreur lors de la suppression de l'assurance!");
      }
    }
  };

  const resetForm = () => {
    setNom('');
    setDescription('');
    setCategorie('');
    setPrix('');
    setEngagement('')
    setPrixVeloInferieur('');
    setStatus('');
    setSecteur('');
    setAssureur('');
    setImage(null);
    setPdfIpid(null);
    setPdfNotice(null);
    setGuarantees(prev => prev.map(g => ({ ...g, checked: false, franchisePercent: '', minFranchise: '' })));
  };

  return (
    <div className="card shadow mb-4">
      <div className="card-header py-3 d-flex justify-content-between align-items-center">
        <h6 className="m-0 font-weight-bold text-primary">Paramètres de l'assurance</h6>
        <button className="btn btn-secondary" onClick={() => setIsLocked(!isLocked)}>
          <FontAwesomeIcon icon={isLocked ? faLock : faUnlock} />
        </button>
      </div>
      <div className="card-body">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Nom de l'assurance</label>
            <input type="text" className="form-control" value={nom} onChange={(e) => setNom(e.target.value)} required disabled={isLocked} />
          </div>
          <div className="form-group">
            <label>Code Produit</label>
            <input
              type="text"
              className="form-control"
              value={code_product}
              readOnly
            />
          </div>
          <div className="form-group">
            <label>Description</label>
            <textarea className="form-control" value={description} onChange={(e) => setDescription(e.target.value)} required disabled={isLocked} />
          </div>
          <div className="row">
            <div className="col-md-6 form-group">
              <label>Prix</label>
              <input type="number" className="form-control" value={prix} onChange={(e) => setPrix(e.target.value)} required disabled={isLocked} />
            </div>
            <div className="col-md-6 form-group">
              <label>Prix Vélo Minimum</label>
              <input type="number" className="form-control" value={prixVeloInferieur} onChange={(e) => setPrixVeloInferieur(e.target.value)} disabled={isLocked} />
            </div>
          </div>
          <div className="form-group">
            <label>Statut</label>
            <select className="form-control" value={status} onChange={(e) => setStatus(e.target.value)} required disabled={isLocked}>
              <option value="">Sélectionner un statut</option>
              {statuses.map(stat => <option key={stat.id} value={stat.id}>{stat.name}</option>)}
            </select>
          </div>
          <div className="form-group">
            <label>Assureur</label>
            <select className="form-control" value={assureur} onChange={(e) => setAssureur(e.target.value)} required disabled={isLocked}>
              <option value="">Sélectionner un assureur</option>
              {assureurs.map(a => <option key={a.id} value={a.id}>{a.nom}</option>)}
            </select>
          </div>
          <div className="form-group">
            <label>Secteur</label>
            <select className="form-control" value={secteur} onChange={(e) => setSecteur(e.target.value)} required disabled={isLocked}>
              <option value="">Sélectionner un secteur</option>
              {secteurs.map(sec => <option key={sec.id} value={sec.id}>{sec.nom}</option>)}
            </select>
          </div>
          <div className="form-group">
            <label>Catégorie</label>
            <select className="form-control" value={categorie} onChange={(e) => setCategorie(e.target.value)} required disabled={isLocked}>
              <option value="">Sélectionner une catégorie</option>
              {categories.map(cat => <option key={cat.id} value={cat.id}>{cat.nom}</option>)}
            </select>
          </div>
          <div className="form-group">
              <label>Engagement (mois)</label>
              <input type="number" className="form-control" value={engagement} onChange={(e) => setEngagement(e.target.value)} required disabled={isLocked} />
          </div>
          <div className="form-group">
            <label>Garanties</label>
            {guarantees.map((guarantee, index) => (
              <div key={guarantee.name} className="border p-2 mb-2">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={guarantee.checked}
                    onChange={(e) => handleGuaranteeChange(index, 'checked', e.target.checked)}
                    disabled={isLocked}
                  />
                  <label className="form-check-label">{guarantee.label}</label>
                </div>
                <input
                  type="number"
                  className="form-control mt-2"
                  placeholder="Pourcentage de la franchise"
                  value={guarantee.franchisePercent}
                  onChange={(e) => handleGuaranteeChange(index, 'franchisePercent', e.target.value)}
                  disabled={isLocked}
                />
                <input
                  type="number"
                  className="form-control mt-2"
                  placeholder="Franchise minimum"
                  value={guarantee.minFranchise}
                  onChange={(e) => handleGuaranteeChange(index, 'minFranchise', e.target.value)}
                  disabled={isLocked}
                />
              </div>
            ))}
          </div>
          <div className="form-group">
            <label>Avantages</label>
            {avantages.map((avantage, index) => (
              <div key={index} className="border p-2 mb-2 rounded">
                <label>Titre</label>
                <input
                  type="text"
                  className="form-control"
                  value={avantage.titre}
                  onChange={(e) =>
                    setAvantages((prev) =>
                      prev.map((item, i) => i === index ? { ...item, titre: e.target.value } : item)
                    )
                  }
                  required
                  placeholder="Titre de l'avantage"
                  disabled={isLocked}
                />
                <label>Description</label>
                <textarea
                  className="form-control"
                  value={avantage.description}
                  onChange={(e) =>
                    setAvantages((prev) =>
                      prev.map((item, i) => i === index ? { ...item, description: e.target.value } : item)
                    )
                  }
                  required
                  placeholder="Description de l'avantage"
                  disabled={isLocked}
                />
                <button
                  type="button"
                  className="btn btn-danger btn-sm mt-2"
                  onClick={() =>
                    setAvantages((prev) => prev.filter((_, i) => i !== index))
                  }
                  disabled={isLocked}
                >
                  Supprimer
                </button>
              </div>
            ))}
            <button
              type="button"
              className="btn btn-primary mt-2"
              onClick={() => setAvantages([...avantages, { titre: '', description: '' }])}
              disabled={isLocked}
            >
              Ajouter un avantage
            </button>
          </div>

          <div className="form-group">
            <label>Image</label>
            <input type="file" className="form-control" onChange={(e) => setImage(e.target.files[0])} disabled={isLocked} />
            {image && <img src={image} alt="Aperçu" className="img-thumbnail mt-2" />}
          </div>
          <div className="form-group">
            <label>PDF IPID</label>
            <input type="file" className="form-control" onChange={(e) => setPdfIpid(e.target.files[0])} disabled={isLocked} />
            {pdfIpid && (
              <a href={handlePdfClick(pdfIpid)} target="_blank" rel="noopener noreferrer" className="btn btn-primary mt-2">
                Voir PDF IPID
              </a>
            )}
          </div>
          <div className="form-group">
            <label>PDF Notice</label>
            <input type="file" className="form-control" onChange={(e) => setPdfNotice(e.target.files[0])} disabled={isLocked} />
            {pdfNotice && (
              <a href={handlePdfClick(pdfNotice)} target="_blank" rel="noopener noreferrer" className="btn btn-primary mt-2">
                Voir PDF Notice
              </a>
            )}
          </div>
          <button type="submit" className="btn btn-primary" disabled={isLocked}>
            {selectedAssurance ? 'Mettre à jour' : 'Ajouter'}
          </button>
          {selectedAssurance && <button type="button" className="btn btn-danger ml-2" onClick={handleDeleteAssurance} disabled={isLocked}>Supprimer</button>}
        </form>
      </div>
    </div>
  );
};

export default AssuranceForm;
